import React from "react";
import "./styles/styles.scss";
// <img className="homepagemainimage" src={require('../../images/bloom-blossoms-buds-87452.jpg')} />
const HomePage = () => {
  return (
    <div className="grid homepage">
      <div className="companytitle">{`Trayer's Farm & Greenhouse`}</div>

      <div className="ownerimage"></div>

      <div className="mainContent">
        {/*<img className="mainContentImage" src={`../../greenhouse1.jpg`} />*/}
        <div className="post-container">
          <div className="post-thumb-left">
            {/*<img src={`greenh.jpg`} />*/}
          </div>
          {/**
          <div className="post-content-left">
            <h3 className="post-title-left">Business Information</h3>
           
            <p>
              🌷 We will be open for business starting April 1st from 9am-5pm
              (Mon-Sat)
            </p>

          
        <p>🌼 We know that you are all ready and excited to start the growing season. We are looking forward to seeing you all.</p>


        <p>🌼 We know that you are all ready and excited to start the growing season. There are plenty of plants so no need to feel like you have to rush in.</p>
 
        <p>💐 We want to remind everyone of the importance of social distancing and we have put measures in place that we will be outlining in a separate post to ensure the safety of our customers.</p>

        <p>🌿 We also want to discourage people from coming in groups and recommend that you not bring your children shopping with you.</p>

        <p>🌳 We are taking every precaution to maintain a safe and clean environment for our customers and ask that you do your part and remain home if you are not feeling well.</p>

       
        <p>{`Now open for this season!`}</p>


   <p>We are currently CLOSED for the season.</p>
          </div>
  */}
        </div>

        <div className="post-container">
          <div className="post-thumb-left">
            {/*<img src={`greenh.jpg`} />*/}
          </div>
          <div className="post-content-left">
            <h3 className="post-title-left">Hours</h3>
            {/** <p>{`We are closed for the season from August 1st till March 31st`}</p>**/}
            {/**
            <p>{`Monday - Friday (9am-7pm)`}</p>
            <p>{`Saturday (9am-5pm)`}</p>
            <p>{`Closed on Sunday`}</p>
          <p style={{'color':'red'  }}>{`We will be running a sale on select plants from now through July 17th!`}</p>
             */}

            {/*<p> The greenhouse will reopen April 1, 2022. </p>*/}
            {/**<p> The greenhouse will open April 1, 2020. </p> */}

             
          <p>{`Now open for this season!`}</p>
          <p>{`April 1st - April 20th: Mon. -Sat. (9am-5pm)`}</p>
          <p>{`April 21st - May 31st: Mon. - Fri. (9am-7pm) & Sat. (9am-5pm)`}</p>
          <p>{`June 1st - July 31st: Mon. - Sat (9am-5pm)`}</p>

 
       
         {/**<p>{`We will be closed for the season from July 31th till March 31st`}</p> */} 
          
         {/*   
         <p>{`We are only open on Sundays in May (12pm-5pm)`}</p> 
          <p>{`Now open for this season!`}</p>
          <p>{`*Memorial Day - (9am-5pm)`}</p>
          <p>{`Monday - Friday (9am-7pm)`}</p>
          <p>{`Saturday (9am-5pm)`}</p>
          <p>{`Our last day open for this season is July 3rd`}</p>
  */}
          </div>
        </div>
      </div>
      <div className="post-container-foot">
        <div className="foot">
          <div className="footer">
            <p>Phone : 1-(717)-328-2456</p>
            <p>Address : 11452 Welsh Run Road Mercersburg PA, 17236</p>
          </div>
        </div>
      </div>
      {/*
        <div className="homepage__main">


          <div className="homepage__mainAbout">
          <h2>{`Trayer's Farm`}</h2>
          <p>{`Closed for this season!`}</p>
          */}
      {/**
                    <p>{`Now open for this season!`}</p>
          <p>{`April 1st - April 20th: Mon. -Sat. (9am-5pm)`}</p>
          <p>{`April 21st - June 14th: Mon. - Fri. (9am-7pm) & Sat. (9am-5pm)`}</p>
          <p>{`June 15th - July 31st: Mon. - Sat (9am-5pm)`}</p>
          <p>{`We will be closed for the season from August 1st till March 31st`}</p>
          <p>{`We are only open on Sundays in May (12pm-5pm)`}</p>
           */}
      {/*
          </div>

        </div>
 */}

      {/*
        <div className="homepage__flowsersImage"></div>
        <div className="homepage__flowsers">
          
          <h3>{`Annuals & Perennials`}</h3>
          <p>stuff about plants</p>
        </div>
        <div className="homepage__vegetablesImage"></div>
        <div className="homepage__vegetables">
        
        <h3>{`Vegetables & Herbs`}</h3>
        <p>stuff about Vegetables</p>
        </div>

       
    

        
        <div className="homepage__feature_Procuts">
          <p>{`Now Open for this season!`}</p>
          <p>{`April 1st - April 20th: Mon. -Sat. (9am-5pm)`}</p>
          <p>{`April 21st - June 14th: Mon. - Fri. (9am-7pm) & Sat. (9am-5pm)`}</p>
          <p>{`June 15th - July 31st: Mon. - Sat (9am-5pm)`}</p>
          <p>{`We will be closed for the season from August 1st till March 31st`}</p>
          <p>{`We are only open on Sundays in May (12pm-5pm)`}</p>
        </div>
         */}
      {/*
      <div className="homepage__subflowers">
        <p>flowsers</p>
      </div>

      <div className="homepage__subherbveg">
        <p>Herbs and Veg</p>
      </div>

      <div className="homepage__subsupplies">
        <p>supplies</p>
      </div>

      <div className="homepage__subcontainers">
        <p>containers</p>
      </div>
    
      <div className="homepage__about">
        <p>we are farmers that farm stuff.</p>
      </div>
*/}
    </div>
  );
};

export default HomePage;
